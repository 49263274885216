const SET_PROCESSING = (state, status) => {
  state.isProcessing = status
}

const SET_USER = (state, user) => {
  state.user = user
}

const CONNECT_USER = (rootState, status) => {
  rootState.isConnected = status
}

const SET_REFRESH_TOKEN = (state, token) => {
  state.refresh_token = token
}

const SET_ACCESS_TOKEN = (state, token) => {
  state.access_token = token
}

const SET_XCSRF_TOKEN = (state, token) => {
  state.xcsrf_token = token
}

export default {
  SET_PROCESSING,
  SET_USER,
  CONNECT_USER,
  SET_REFRESH_TOKEN,
  SET_ACCESS_TOKEN,
  SET_XCSRF_TOKEN
}
