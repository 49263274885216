<template>
  <div class="homepage" v-bind:style="background">
  </div>
</template>

<script>

export default {
  name: 'Home',

  components: {
  },

  computed: {
    background() {
      return {
        backgroundImage: `url(${require('@/assets/images/background/home.png')})`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .homepage {
    height: calc(100% - 100px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
