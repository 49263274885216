<template>
  <div class="new-password-form">
    <p
        v-if="validationError"
        class="message error"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="dispatchError"
        class="message error"
    >
      {{ dispatchError }}
    </p>

    <form action="">
      <input
        v-model="form.password"
        placeholder="password"
        type="password"
        class="form-input form-input--full">
      <div class="form-submit">
        <button
          class="btn btn-red uppercase my-5"
          :class="{ 'disabled' : loading } "
          @click.prevent="handleSubmit">
          Enregistrer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from '@/components/commons/Alert'

export default {
  components: {
    Alert
  },

  data: () => ({
    validationError: false,
    form: {
      password: ''
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    },

    dispatchError () {
      return this.$store.state.authentication.errorMessage
    }
  },

  methods: {
    handleSubmit () {
      this.$store.dispatch('closeErrorMessage')
      this.validationError = false;
      if(!this.form.password) {
        this.validationError = 'Mot de passe requis'
        return
      }
      this.$store.dispatch('resetPassword', {
        name: this.$route.query.user,
        temp_pass: this.$route.query.token,
        new_pass: this.form.password
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.new-password-form {
  width: 75%;

  @screen tablet {
    width: 50%;
  }
}
</style>
