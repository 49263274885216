<template>
  <div class="quote-validate">
      <Summary />
      <Sidebar v-if="$route.name === 'clientsListing'"/>
  </div>
</template>

<script>
import Summary from "@/components/quotes/summary/Summary";
import Sidebar from "@/components/quotes/summary/Sidebar";

export default {
  name: "Validate",

  components: {Sidebar, Summary},
}
</script>

<style lang="scss" scoped>

</style>
