<template>
  <div class="page-title">
    <div class="flex justify-center items-center">
      <icon v-if="pageIcon" :icon="pageIcon" />
      <h1 v-html="pageTitle" />
    </div>
    <p class="quoteClient" v-if="$route.name.includes('Quotes')">{{ quoteClient }}</p>
    <p v-if="pageIntroduction" v-html="pageIntroduction" />
  </div>
</template>

<script>

export default {
  name: "PageTitle",

  props: {
    icon: {
      type: String,
      default : '',
      required: false
    },
    title: {
      type: String,
      default : '',
      required: false
    },
    introduction: {
      type: String,
      default : '',
      required: false
    }
  },

  computed: {
    pageTitle() {
     return this.$route.meta.title ? this.$route.meta.title : this.title
    },

    pageIcon() {
      return this.$route.meta.icon ? this.$route.meta.icon : this.icon
    },

    pageIntroduction() {
      return this.$route.meta.introduction ? this.$route.meta.introduction : this.introduction
    },

    quoteClient() {
      const client = this.$store.state.clients.current
      let clientDisplay = ''
      if(client.companyName) {
        clientDisplay += client.companyName + ' - '
      }
      if(client.lastname) {
        clientDisplay += client.lastname + ' '
      }
      if(client.firstname) {
        clientDisplay += client.firstname
      }
      return clientDisplay
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    @apply my-16 text-center;

    .icon { @apply mr-3; }

    p:not(.quoteClient) { @apply mt-6; }

    .quoteClient { @apply uppercase; }
  }
</style>
