<template>
  <div class="ask-password-form">
    <p
        v-if="emailSended"
        class="message"
    >
      Un email de réinitialisation a été envoyé
    </p>
    <p
        v-if="validationError"
        class="message error"
    >
      {{ validationError }}
    </p>
    <p
        v-else-if="dispatchError"
        class="message error"
    >
      {{ dispatchError }}
    </p>
    <form>
<!--        <div class="form-item">-->
          <input
              v-model="form.email"
              placeholder="adresse@mail.com"
              type="email"
              class="form-input form-input--full">
<!--        </div>-->
      <div class="form-submit">
        <button
          class="btn btn-red uppercase my-5"
          :class="{ 'disabled' : loading}"
          @click.prevent="handleSubmit">
          Envoyer
        </button>
        <a
          class="text-white"
          @click="handleSwitchForm">
          Retour
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from '@/components/commons/Alert'

export default {
  components: {
    Alert
  },

  data: () => ({
    validationError: false,
    form: {
      email: ''
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    },

    dispatchError () {
      return this.$store.state.authentication.errorMessage
    },

    emailSended () {
      return this.$store.state.authentication.emailSended
    }
  },

  methods: {
    handleSubmit () {
      this.$store.dispatch('closeErrorMessage')
      this.$store.dispatch('closeEmailSended')
      this.validationError = false;
      if(!this.form.email) {
        this.validationError = 'Email requis'
        return
      }
      this.$store.dispatch('requestNewPasswordMail', this.form.email)
    },

    handleSwitchForm () {
      this.$store.dispatch('closeErrorMessage')
      this.$emit('switch')
    }
  }
}
</script>

<style lang="scss" scoped>
  .ask-password-form {
    width: 75%;

    @screen tablet {
      width: 50%;
    }
  }
</style>

