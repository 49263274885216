import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $axios from './axios'

import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'

import iconComponent from '@/components/commons/Icon'
import modalComponent from '@/components/commons/Modal'

// Import des styles globaux
import '@/assets/styles/main.scss'

import Cookies from "js-cookie";

Vue.config.productionTip = false

// Axios interceptor
$axios.interceptors.request.use((config) => {
  if (store.getters.accessToken && router.currentRoute.name !== 'login') {
    config.headers.Authorization = `Bearer ${store.getters.accessToken}`
  }

  if((config.method === 'post' || config.method === 'patch') && router.currentRoute.name !== 'login') {
    config.headers['X-CSRF-Token'] = store.state.authentication.xcsrf_token
  }

  return config
})

const responseInterceptor = $axios.interceptors.response.use(
  res => res,
  err => {
    const status = err.response.status
    if ((status !== 403 && status !== 404 && status !== 401) || router.currentRoute.name === 'login') {
      return Promise.reject(err)
    }

    $axios.interceptors.response.eject(responseInterceptor)

    if (status === 403) {
      Vue.notify({
        group: 'notification',
        text: 'Droits insuffisants',
        type: 'warn'
      })
      router.push('/403')
    } else if(status === 404 && router.currentRoute.params.slug) {
      router.push('/404')
    } else if (status === 401) {
      // Le store vuex est vidé
      store.commit('CONNECT_USER', false)
      store.commit('SET_ACCESS_TOKEN', null)
      store.commit('SET_REFRESH_TOKEN', null)
      store.commit('SET_USER', {})

      // Les cookies sont effacés
      Object.keys(Cookies.get()).map(cookieName => Cookies.remove(cookieName))

      Vue.notify({
        group: 'notification',
        text: 'Reconnexion nécessaire',
        type: 'warn'
      })

      // L'user est renvoyé sur la page de login
      router.push({ name: 'login' })
    }
  }
)

Vue.prototype.$axios = $axios

// Import des icones custom depuis le dossier d'icone pour un usage global de ce
// composant. Ainsi, pas besoin de l'appeler à chaque fois. Il faut ensuite
// renseigner le nom de l'icone (idem au nom fichier) passée dans les props.
Vue.component('icon', iconComponent)
Vue.component('modal', modalComponent)

Vue.use(Vuelidate)

// https://www.npmjs.com/package/vue-notification
Vue.use(Notifications)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
