<template>
  <div class="quote">
    <div v-if="stepNumber" class="stepNumber">{{ stepNumber }}</div>
    <router-view />
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
export default {
  name: "Quotes",

  computed: {
    stepNumber() {
      let number = ''
      if(this.$route.name.includes('Elevage')) {
        number = '01.'
      } else if(this.$route.name.includes('Logettes')) {
        number = '02.'
      } else if(this.$route.name.includes('Batiment')) {
        number = '03.'
      }
      return number
    }
  },

  components: { PageTitle },

   async beforeMount() {
    if(this.$route.name.includes('QuotesEdit')) {
       await this.$store.dispatch('getQuote', this.$route.params.quoteId)

    } else if (this.$route.name.includes('QuotesDuplicate')) {
      await this.$store.dispatch('getQuote', this.$route.params.quoteId)
      this.$store.commit('RESET_PRODUITS_VALUE')

    } else {
       await this.$store.dispatch('resetQuotesState')
    }
   await this.$store.dispatch('getTaxos')
   await this.$store.dispatch('getProduits')
   await this.$store.dispatch('getRiSettings')
   await this.$store.dispatch('getClient', this.$route.params.clientId)
   await this.$store.dispatch('getStationsMeteo', { clientId: this.$route.params.clientId })
  }
}
</script>

<style scoped>

</style>
