import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import Cookies from 'js-cookie'

export default ({
  state: {
    isProcessing: false,
    errorMessage: '',
    isConnected: !!Cookies.get('bioret_access_token'),
    access_token: Cookies.get('bioret_access_token') || undefined,
    refresh_token: Cookies.get('bioret_refresh_token') || undefined,
    xcsrf_token: Cookies.get('bioret_xcsrf_token') || undefined,
    user: localStorage.getItem('bioret_user_data') !== 'undefined' ? JSON.parse(localStorage.getItem('bioret_user_data')) : {}
  },

  actions,
  mutations,
  getters
})
