<template>
  <div id="notfound">
    <div class="container">
      <h1>Accès refusé.</h1>
      <router-link to="/">Retour à l'accueil</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccessDenied'
}
</script>

<style lang="scss" scoped>
#notfound {
  @apply mt-8 self-stretch;
}
</style>
