<template>
  <div class="page">
    <Header />
    <router-view></router-view>
    <notifications group="notification" position="bottom left" />
  </div>
</template>

<script>

import Header from "@/components/Header";
export default {
  components: {Header}
}
</script>

<style lang="scss" scoped>
 .page {
   height: 100%;
 }
</style>
