<template>
  <div class="alert">
    <p> {{ message }}</p>
    <button
      class="alert-close"
      @click="handleClose">x</button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Une erreur est survenue',
      required: false
    }
  },

  methods: {
    handleClose () {
      this.$store.dispatch('closeErrorMessage')
    }
  }
}
</script>
