<template>
  <div class="header">
    <div class="logo">
      <Logo />
    </div>
    <div class="account">
      <div class="icon">
        <icon :icon="'account'" />
      </div>
      <div class="text">
        <router-link v-if="$route.name === 'home'" :to="{ name: 'login' }">Connectez-vous</router-link>
        <div v-else-if="$store.getters.isAuthenticated">
          <p>{{ name }}</p>
          <a @click="handleLogout" class="underline cursor-pointer">Se déconnecter</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/commons/Logo";
export default {
  name: "Header",
  components: {Logo},
  computed: {
    name() {
      return this.$store.state.authentication.user.lastname + ' ' + this.$store.state.authentication.user.firstname
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('disconnectUser')
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;

    @screen tablet {
      height: 100px;
      flex-direction: row;
      justify-content: flex-end;

      .logo, .account { width: calc(100% / 3); }
    }

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      @screen tablet { padding: 0; }
    }

    .account {
      display: flex;
      align-items: center;
      padding: 10px;

      @screen tablet {
        padding: 20px;
        justify-content: flex-end;
      }

      .icon { margin-right: 5px; }
    }
  }
</style>
