<template>
  <div class="login-form">
    <form>
      <div
        v-if="form.submitted && (!$v.form.login.required || !$v.form.password.required)"
        class="message error">
        Email et mot de passe requis
      </div>
<!--        <div class="form-item">-->
          <input
              v-model="$v.form.login.$model"
              placeholder="Votre identifiant"
              type="text"
              class="form-input form-input--full"
              :class="{ 'border-red-700': $v.form.login.$error }"
          >
<!--        </div>-->
<!--        <div class="form-item">-->
          <input
            v-model="$v.form.password.$model"
            placeholder="Votre mot de passe"
            type="password"
            class="form-input form-input--full"
            :class="{ 'border-red-700': $v.form.password.$error }"
          >
<!--        </div>-->
      <div class="form-submit">
        <button
            class="btn btn-red uppercase my-5"
            :class="{ 'disabled' : loading}"
            @click.prevent="handleLogin">
          Se connecter
        </button>
<!--        <a
            class="block text-white"
            @click="handleSwitchForm">Mot de passe oublié ?</a>-->
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Vue from "vue";

export default {
  data: () => ({
    form: {
      login: '',
      password: '',
      submitted: false
    }
  }),

  computed: {
    loading () {
      return this.$store.state.authentication.isProcessing
    }
  },

  validations: {
    form: {
      login: {
        required
      },
      password: {
        required
      }
    }
  },

  methods: {
    handleLogin () {
      this.form.submitted = true
      this.$v.$touch()

      if(!this.$v.$invalid) {
        const payload = {
          username: this.form.login,
          password: this.form.password
        }
        this.$store.dispatch('requestToken', payload);
      }
    },

   /* handleSwitchForm () {
      this.$emit('switch')
    }*/
  }
}
</script>

<style lang="scss" scoped>
  .login-form {
    width: 75%;

    @screen tablet {
      width: 50%;
    }
  }
</style>
