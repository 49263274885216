import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import authenticationModule from './modules/authentication'
import clientsModule from './modules/clients'
import quotesModule from './modules/quotes'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    authentication: authenticationModule,
    clients: clientsModule,
    quotes: quotesModule,
  },

  state: {
  },

  actions,
  mutations,
  getters
})
