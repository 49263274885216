/**
 * Permet de connaître la route précédente dans le cas de Children
 * @param {*} String le from
 */
const savePreviousRoute = ({ commit }, from) => {
  commit('SET_PREVIOUS_ROUTE', from)
}

export default {
  savePreviousRoute
}
