<template>
  <div class="login" v-bind:style="background">
    <div class="main">
      <img src="@/assets/images/dairy-success.png" alt="dairy-success" class="mb-12" />
<!--      <form-password-new v-if="$route.query.newPassword"/>-->

          <form-login />

<!--      <form-login
          v-else-if="!resetPassword"
          @switch="resetPassword= true"/>-->

<!--      <form-password-ask
          v-else
          @switch="resetPassword= false"/>-->
    </div>
  </div>
</template>

<script>
import Logo from '@/components/commons/Logo.vue'
import FormLogin from './FormLogin.vue'
import FormPasswordAsk from './FormPasswordAsk.vue'
import FormPasswordNew from './FormPasswordNew.vue'

export default {
  name: 'LoginForm',

  components: {
    Logo,
    FormLogin,
    // FormPasswordAsk,
    // FormPasswordNew
  },

  data: () => ({
    // resetPassword: false
  }),

  computed: {
    background () {
      return {
        backgroundImage: `url(${require('@/assets/images/background/login.png')})`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 100px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
